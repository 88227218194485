@tailwind base;
@tailwind components;
@tailwind utilities;

/* Graphik Light - WOFF2 */
@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Light-Web.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Graphik Regular - WOFF2 */
@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
       url('./assets/fonts/Graphik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Graphik Medium - WOFF2 */
@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Medium-Web.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.App {
  font-family: Graphik, Helvetica, sans-serif;
}
